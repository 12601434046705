import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MassiveChangesEditComponent } from './massive-changes-edit.component';
import { MassivePaymentsModule } from './massive-payments/massive-payments.module';
import { MassiveOffertModule } from './massive-offert/massive-offert.module';

@NgModule({
  imports: [
    CommonModule,
    MassivePaymentsModule,
    MassiveOffertModule
  ],
  declarations: [MassiveChangesEditComponent],
  exports: [MassiveChangesEditComponent]
})
export class MassiveChangesEditModule { }
