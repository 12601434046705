import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CtBinaryOperator, CtButtonConfiguration, CtControlService, CtControlTypes, CtFormConfiguration, CtGenericDialogConfiguration, CtModelConfiguration, CTModelDatatableFilter, CtModelDatatableOperators, CtModelService, CtSelectControlOptions, CtSelectControlValue, CtSortOrderDescriptorParameter, CtThemeTypes, MAT_RAISED_PRIMARY, SnackbarService } from '@ctsolution/ct-framework';
import { CtWebapiGenericResponse, CtWebapiService, DataRequest, MethodEnum } from '@ctsolution/ct-webapi';
import { WorkOrderPayment } from 'projects/ct-manager/src/app/_core/classes/work-order-payments';
import { CTMGeneralService } from 'projects/ct-manager/src/app/_core/lib/general.service';
import { MassiveChangesService } from '../../massive-changes.service';
import { DatePipe } from '@angular/common';
import { WorkOrderRouteData } from 'projects/ct-manager/src/app/_core/route-data/work-order.route-data';

@Component({
  selector: 'app-massive-offert',
  templateUrl: './massive-offert.component.html',
  styleUrls: ['./massive-offert.component.scss']
})
export class MassiveOffertComponent implements OnInit {

  private contactControl = this.generalService
  .getLookupControlDefaultConfiguration({
    name: 'Contact',
    controller: 'Contact',
    key: 'Name',
    action: 'Lookup',
    required: true,
  })
  .setFlexSpacePercentage(50)
  .setLabel('Amministratore')
  .configureOptions<CtSelectControlOptions>(options => {

    if (!options) options = CtSelectControlOptions.create();

    options
      .setNullValueEnabled(true)
      .setLookupFilter((value) => {

        const adminFilter = CTModelDatatableFilter.create().setField("Type.Code").setOperatorType(CtBinaryOperator.Equal).setValue("ADMIN");

        const filters: CTModelDatatableFilter[] = [adminFilter];

        if (value) {

          filters
            .push(
              CTModelDatatableFilter
                .create()
                .setField("[Name] is not null AND Contains([Name], ?)")
                .setValue([value])
                .setOperatorType(CtBinaryOperator.OrOperator),
              CTModelDatatableFilter
                .create()
                .setField("[Surname] is not null AND Contains([Surname], ?)")
                .setValue([value])
                .setOperatorType(CtBinaryOperator.OrOperator),
            );
        }

        const operators = CtModelDatatableOperators
          .create()
          .setFilters(filters);

        operators
          .Pagination
          ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(CtSortOrderDescriptorParameter.create('Name')))

        return operators;

      })

  });

  private offerTypeLookup = this.generalService
  .getLookupControlDefaultConfiguration({
    name: 'Utility',
    controller: 'OfferType',
    key: 'Name',
    action: 'Lookup'
    })
  .setFlexSpacePercentage(50)

  private offerCodeControl = this.generalService.getLookupControlDefaultConfiguration({
    name: 'OfferCode',
    controller: 'Offer',
    key: 'Name',
    action: 'Lookup'
  })
  .setNullValueEnabled(true);

 

  searchButton : CtButtonConfiguration = CtButtonConfiguration.create()
  .setAction(() => this.searchWorkOrder())
  .setLabel("Search")
  .setMatherialOptions(MAT_RAISED_PRIMARY)
  .setClass("button-cntr");

  submitButton : CtButtonConfiguration = CtButtonConfiguration.create()
  .setAction(() => this.submit())
  .setLabel("Submit")
  .setMatherialOptions(MAT_RAISED_PRIMARY)
  .setClass("button-cntr");

  formConfiguration: CtFormConfiguration | null = null;
  submitFormConfiguration: CtFormConfiguration | null = null;

  elements: WorkOrderPayment[] | null = null;
  selectedElements = [];



  constructor(private snackBar : SnackbarService,private datePipe : DatePipe, private generalService : CTMGeneralService, private fb: FormBuilder, private ctControlService : CtControlService, private cdr : ChangeDetectorRef, private _webapi : CtWebapiService,private massiveChangesService : MassiveChangesService) { 

    this.formConfiguration = CtFormConfiguration
    .create(this.fb.group({}))
    .setTheme(CtThemeTypes.MATERIAL)
    .setControls([this.contactControl,this.offerTypeLookup]);

  }

  ngOnInit() {

      
  }

  private searchWorkOrder(){

    this.formConfiguration?.form?.markAllAsTouched();
    
    if(this.formConfiguration?.form?.valid == false) return;

    const param = {

      ContactOid : this.formConfiguration?.form?.get('Contact')?.value.value,
      OfferTypeOid : this.offerTypeLookup.control?.value.value

    }

    this._webapi.post(getWorkOrderFilterList(param)).subscribe((response: CtWebapiGenericResponse<any>) => {

      this.elements = [];
      this.elements = [...response.Result];
      this.setupSubmitForm();
      this.cdr.detectChanges();
    });

  }


  private setupSubmitForm() {

    this.offerCodeControl.configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        .setNullValueEnabled(false)
        .setLookupFilter(() => {

          const operators = CtModelDatatableOperators
            .create()

          operators
            .Pagination
            ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(CtSortOrderDescriptorParameter.create('Name')))

          const filters: CTModelDatatableFilter[] = [
            CTModelDatatableFilter
              .create()
              .setField('OfferType.Oid')
              .setValue( this.offerTypeLookup.control?.value.value)
              .setOperatorType(CtBinaryOperator.Equal),
          ];

          operators
            .setFilters(filters);

          return operators

        })
        ?.setLookupResponseMapper(
          (response: CtWebapiGenericResponse<CtModelConfiguration<any>>) => {

            const result = (<Array<any>>response.Result.DataSource ?? []);

            if (result.length) {

              const toReturn = result.map(elm => CtSelectControlValue.create().setLabel(elm.Description).setValue(elm));

              return toReturn;

            }

            return [];
          })

    });

    (<CtSelectControlOptions>this.offerCodeControl.options).notifyValuesUpdated();

    this.submitFormConfiguration = CtFormConfiguration
    .create(this.fb.group({}))
    .setTheme(CtThemeTypes.MATERIAL)
    .setControls([this.offerCodeControl]);

  }

  private submit(){

    this.submitFormConfiguration?.form?.markAllAsTouched();

    if(this.selectedElements.length < 1) this.snackBar.open("Seleziona almeno un elemento!");

    if(this.submitFormConfiguration?.form?.valid == false || this.selectedElements.length < 1) return;

    const WorkOrdersOid: number[] = this.selectedElements.map((element : any) => element.Oid);

    const dialogConfig = CtGenericDialogConfiguration.create()
    .setTitle("Attenzione")
    .setContent(`Stai per sostituire L'Offerta precedente con l'Offerta ${this.offerCodeControl.control?.value.value.Description} per il ${WorkOrdersOid.length} Contratti dell'Utility ${this.offerTypeLookup.control?.value.label}`)
    .setSubmitLabel("Conferma")
    .setCancelLabel("Annulla")

    this.massiveChangesService.open(dialogConfig).afterClosed().subscribe((res : any) => {

         if(res)
        {
          this._webapi.post(MassiveChangeOffer({
            WorkOrdersOid: WorkOrdersOid,
            NewOfferOid: +this.offerCodeControl.control?.value.value.Code
          })).subscribe((response: CtWebapiGenericResponse<any>) => {

            this.snackBar.onSuccess()
            
          });
      
        }

    });

  }
}

export const getWorkOrderFilterList : (param : any) => DataRequest = (param : any) => new DataRequest()
.setController([WorkOrderRouteData().controller!])
.setAction('GetWorkOrdersForMassiveChangeOffer')
.setMethod(MethodEnum.POST)
.setBody(param);


export const MassiveChangeOffer : (param : any) => DataRequest = (param : any) => new DataRequest()
.setController([WorkOrderRouteData().controller!])
.setAction('MassiveChangeOffer')
.setMethod(MethodEnum.POST)
.setBody(param);